.th-home {

    & .logo-piece {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
        left: 0;
        right: 0;
        font-size: 100px;
        width: 100px;
    }

    & h3 {
        margin-top: 140px;
        padding: 5px;
        border-bottom: 1px solid var(--base02);
    }

    & .league-list {
        flex-grow: 1;
        padding: 5px;
        border-bottom: 1px solid var(--base02);
    }

    & .pad {
        margin-bottom: 20px;
    }
}