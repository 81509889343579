/*
https://github.com/parcel-bundler/parcel/issues/329
Theme currently duplicated in .postcssrc.
*/

@import "./themes/base16-harmonic16-light.css";

@import "./reset.css";
@import "./pages/HomePage.css";
@import "./pages/LeaguePage.css";
@import "./pages/SeasonPage.css";
@import "./pages/GameHistoryPage.css";
@import "./pages/GamePage.css";
@import "./pages/HandPage.css";
@import "./pages/VsPage.css";
@import "./pages/stats/StatsPage.css";
@import "./pages/components/HandResult.css";
@import "./pages/components/GameResult.css";
@import "./pages/components/PlayerHand.css";
@import "./pages/components/PlayerChooser.css";
@import "./pages/components/Card.css";

body {
    background: var(--base00);
    color: var(--base06);
    font-family: "Roboto", sans-serif;
    font-size: 19px;
}

a {
  color: var(--base0C);
}

._clubs {
    color: var(--base0B);
}
.active ._clubs,
._clubs .active,
._clubs.active {
    background: var(--base0B);
    color: white;
}

._diamonds {
    color: var(--base0C);
}
.active ._diamonds,
._diamonds .active,
._diamonds.active {
    background: var(--base0C);
    color: white;
}

._hearts {
  color: var(--base0F);
}

.active ._hearts,
._hearts .active,
._hearts.active{
  background: var(--base0F);
  color: white;
}

._spades {
    color: var(--base07);
}
.active ._spades,
._spades .active,
._spades.active {
    background: var(--base07);
    color: white;
}

.th-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 450px;
}

.th-button {
    width: 100%;
    height: 50px;
    background: #48AFF0;
    text-align: center;
    line-height: 50px;
    color: white;

    &.invalid {
        background: #F55656;
    }
}

.th-nav {
    background: var(--base01);
    border-bottom: 1px solid var(--base02);
    padding: 10px 5px 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.th-text-input {
    font-size: 19px;
    border: 1px solid var(--base02);
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}
