.th-game {

    & .time {
        text-align: center;
        padding: 10px 0 20px 0;
    }

    & .names {
        border-bottom: 1px solid var(--base03);
    }

    & .cells {
        display: flex;
        margin-bottom: 10px;

        & .cell {
            flex: 1 1 0;
            display: inline-block;
        }
    }

    & .summary {
        padding-top: 5px;
        line-height: 30px;

        &.cells {
            border-top: 1px solid var(--base03);
        }

        &.no-hands {
            text-align: center;
            margin-top: 25px;
        }
    }

    & .money {
        margin-top: 20px;
        flex-grow: 1;

        & .header {
            border-bottom: 1px solid var(--base03);
            margin-bottom: 10px;
        }
    }

    & .instructions {
        margin-bottom: 30px;
        padding: 5px;
    }

    & .choosers {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        & .chooser-wrapper {
            display: flex;
            justify-content: center;
        }
    }

    & .small {
        width: 25px;
    }
}