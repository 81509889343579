.th-game-history {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    & .chart {
        flex: 1 1 auto;
        height: calc(100% - 100px);
    }

    & .legend {
        flex: 0 0 auto;
        height: 100px;
        padding: 0px 50px;
        display: flex;
        flex-wrap: wrap;

        & .entry {
            margin-right: 20px;
            margin-bottom: 5px;
            display: flex;
        }

        & .sample {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 5px;
        }
    }
}
