.th-hand {

    & .bottom {
        height: 80px;
        padding: 5px;
        border-top: 1px solid black;
        color: white;

        &.valid {
            background: #15B371;
        }

        &.invalid {
            background: #F55656;
        }

        & .success {
            text-align: center;
            font-size: 40px;
            line-height: 80px;
        }
    }
}
