.th-player-hand {
    padding: 6px 4px;
    flex-grow: 1;
    border-top: 1px solid black;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .name-and-score,
    & .charges,
    & .took-specials,
    & .hearts {
        flex: 0 0 auto;
    }

    & .name-and-score {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & h5 {
        line-height: 30px;
        width: 40%;
        color: #aaa;
    }

    & .charges, & .took-specials {
        display: flex;

        & .th-card {
            flex-grow: 1;
        }
    }

    & .hearts {
        display: flex;
        justify-content: space-between;
    }

    & .heart-count {
        flex: 1 1 auto;
        text-align: center;

        &.hidden {
            visibility: hidden;
            pointer-events: none;
         }
    }
}
