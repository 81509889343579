.th-league {

    & h1 {
        font-size: 24px;
        padding: 5px;
    }

    & h3 {
        padding: 5px;
        border-bottom: 1px solid var(--base02);
    }

    & .side-by-side {
        display: flex;
        flex-grow: 1;

        & .left {
            display: flex;
            flex-direction: column;
            flex-grow: 0.75;
            padding-right: 5px;
        }

        & .right {
            display: flex;
            flex-direction: column;
            padding-left: 5px;
            flex-grow: 0.25;
        }
    }

    & .season-list, & .player-list {
        padding: 5px;
        flex-grow: 1;
        line-height: 24px;
    }

}
