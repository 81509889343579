.th-hand-incomplete {
    text-align: center;
    margin-bottom: 10px;
}

.th-hand-result {
    display: flex;
    margin-bottom: 10px;

    &.centered {
        text-align: center;
    }

    & .score {
        flex: 1 1 0;
        display: inline-block;
    }
}